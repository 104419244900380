.p10 {
  padding: 10px;
}
.full-width {
  width: 100%;
}
.px40 {
  padding-left: 40px;
  padding-right: 40px;
}
.display-flex {
  display: flex;
}
.fontw600 {
  font-weight: 600;
}
.fSize12 {
  font-size: 1.2rem;
}
.fontSize18 {
  font-size: 18px;
}
.ml10 {
  margin-left: 10px;
}
.mt50 {
  margin-top: 50px;
}
.mb50 {
  margin-bottom: 50px;
}
.justify-align-center {
  justify-content: center;
  align-items: center;
}
.align-center {
  align-items: center;
}
.colorBlue {
  color: #09427d;
}
.c-pointer {
  cursor: pointer;
}
.ant-tabs-tab {
  padding: 20px 40px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #09427d !important;
}
