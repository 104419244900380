.roles {
  margin: 50px;
  text-align: left;
  .title {
    padding: 30px;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebedf0;
    color: #09427d;
  }
  .me {
    padding: 30px;
    border-bottom: 1px solid #ebedf0;
    .section-title {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: #09427d;
    }
    .user {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &.mine {
        button {
          // background: #09427d;
          // border-radius: 5px;
          // border: 0;
          // padding: 12px 20px;
          // color: #fff;
        }
      }
      &.others {
        margin-bottom: 30px;
        button {
          border: 1px solid rgba(9, 66, 125, 0.12);
          border-radius: 5px;
          padding: 8px 30px;
          background-color: #fff;
        }
      }
      .info {
        p {
          color: #09427d;
          font-weight: 500;
        }
        span {
          color: #aab9c9;
        }
      }
    }
  }
}
