.sidebar {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  background: #09427d;
  text-align: left;
  position: relative;
  z-index: 22;
  .top {
    height: 60px;
    line-height: 80px;
    padding: 15px 25px;
    text-align: center;
    img {
      height: 28px;
    }
  }
  .logout {
    // position: absolute;
    // bottom: 30px;
    color: rgba(255, 255, 255, 0.28);
    font-weight: bold;
    font-size: 15px;
    margin-top: 100px;
  }

  // nav-links

  ul.links {
    padding: 0;
    margin: 80px 0 0;
    min-height: 450px;
    overflow: auto;
    position: relative;
    height: 85vh;
    li {
      cursor: pointer;
      font-size: 15px;
      color: #ffffff73;
      // text-overflow: ellipsis;
      transition: linear all 0.1s;
      font-weight: 600;
      .main-link {
        padding: 20px 50px;
        white-space: nowrap;
        overflow: hidden;
        border-left: 3px solid transparent;
        span {
          display: inline-block;
          width: 35px;
          svg {
            width: 20px !important;
            position: relative;
            top: 4px;
            // fill: #ffffff73;
            stroke: #ffffff73;
          }
        }
        &.active-link {
          background: #00000036;
          color: #fff;
          border-left: 3px solid #ffffff;
          span {
            svg {
              // fill: #fff;
              stroke: #fff;
            }
          }
        }
      }
      .inner {
        height: 0;
        width: 100%;
        overflow: hidden;
        transition: linear all 0.3s;
        &.open-inner {
          height: 145px;
        }
      }
      img {
        margin-right: 30px;
        height: 12px;
      }
      .inner-link {
        padding: 15px 0 15px 40px;
        background-color: rgba(0, 0, 0, 0.1);
        margin: 0;
        &.active-inner-link {
          color: #47a08b;
        }
        .circle {
          width: 8px;
          height: 8px;
          border: 1px solid #47a08b;
          border-radius: 50%;
          margin-right: 30px;
          display: inline-block;
        }
      }
    }
  }
}
