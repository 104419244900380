.video-wrapper {
  width: 1080px;
  height: 600px;
}
.video-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex {
  display: flex;
}
.j-space-between {
  justify-content: space-between;
}
.centeredFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.py-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-20 {
  padding-bottom: 20px;
  padding-top: 20px;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.fontSize9rem {
  font-size: 0.9rem;
}
.fontSize17 {
  font-size: 17px;
}
