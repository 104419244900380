.dashboard {
    width: 100%;
    overflow: hidden;
    .side-wrap {
      position: relative;
      transition: linear all 0.2s;
      float: left;
      width: 250px;
  
      .arrow {
        width: 25px;
        height: 25px;
        line-height: 26px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: 15px;
        right: -15px;
        text-align: center;
        z-index: 25;
        img {
          width: 13px;
          transition: linear all 0.2s;
          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }
    .dash-contents {
      // display: inline-block;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: #fafafa;
      float: left;
      height: 100vh;
      width: calc(100% - 250px);
      padding: 30px 30px 100px;
      width: calc(100% - 250px) !important;
      nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
        .page-title {
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
  
          color: #09427d;
        }
        .filter {
          cursor: pointer;
          padding: 8px 15px;
          background-color: #fff;
          border-radius: 5px;
          color: #09427d;
          // font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.2px;
          border: 0.1px solid rgba(9, 66, 125, 0.123);
        }
        .filter2 {
        }
      }
    }
  }
  