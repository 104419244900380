/* Tabs*/
section .section-title {
  text-align: left;
  color: #212529;
  margin-bottom: 0;
  text-transform: uppercase;
}
#tabs {
  color: #212529;
}
#tabs h6.section-title {
  color: #eee;
}

.nav-tabs {
  overflow: auto;
  overflow-y: hidden;
  flex-wrap: nowrap !important;
}

.nav-item {
  min-width: 200px;
}

.nav-tabs::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

#tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f3f3f3;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 4px solid !important;
  font-size: 20px;
  font-weight: bold;
  outline: none;
}
#tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #09427d;
  font-size: 15px;
  outline: none;
}

.responsive_image {
  width: 100%;
  height: auto;
  /* margin: 20px; */
}

.responsive_small_image {
  width: auto;
  height: auto;
  max-height: 300px;
}

.responsive_video {
  width: 100%;
  height: auto;
  max-height: 500px;
}

.id_doc {
  max-height: 700px;
  height: 700px;
  overflow-y: scroll;
}

.small_doc {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.flex-with-space {
  display: flex;
  column-gap: 2.5em;
  row-gap: 2.5em;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.to_disable_empty .ant-empty-normal {
  display: none;
}
.full-width {
  width: 100%;
}

.pt20 {
  padding-top: 20px;
}
