.tab-container {
  display: flex;
  align-items: center;
  .tab-items {
    color: #09427d;
    font-weight: 600;
    padding: 15px 35px;
  }
  .tab-item-active {
    font-weight: 600;
    color: #ffffff;
    background: #09427d;
    border-radius: 5px;
    padding: 15px 35px;
  }
  .cursor {
    cursor: pointer;
  }
}
.loan-container {
  padding-top: 2rem;
  .loan-header {
    font-size: 1.4rem;
    color: #09427d;
    font-weight: 500;
  }
  .loan-table-item {
    font-weight: 400;
    font-size: 0.9rem;
  }
  .loan-fw500 {
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: capitalize;
  }
}

.paddingTop4 {
  padding-top: 40px;
}
.greyBG {
  background: #fafafa;
}
.dashboard-card {
  height: 170px;
  text-align: left;
  padding: 20px 25px;
  margin-bottom: 30px;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  text-align: left;
  flex-direction: column;
}
.dashboard-card_title {
  color: rgba(9, 66, 125, 0.5);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dashboard-card_value {
  color: #09427d;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.099px;
}
.d-flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
