.merchants {
  .site-card-wrapper {
    .active_bucket {
      border-bottom: 2px solid;
    }

    .crd {
      .ant-card-body {
        padding: 0.5rem;
        text-align: center;
      }
    }
  }
  .single-item {
    cursor: pointer;
    margin-bottom: 8px;
  }
  .trow {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 20px;
    min-height: 60px;
    align-items: center;
    &.thead {
      font-weight: bold;
      margin-bottom: 8px;
      color: rgba(9, 66, 125, 0.49);
      border-radius: 10px;
      &.bordered {
        background: #f7f7f7;
        border: 0.3px solid rgba(9, 66, 125, 0.123);
      }
    }
    &.titem {
      background: #ffffff;
      border-radius: 5px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.44px;
      font-weight: 400;
      color: #09427d;
      &.bordered {
        background: #ffffff;
        border: 0.1px solid rgba(9, 65, 125, 0.123);
      }
    }
    .tdata {
      letter-spacing: 0.44px;
      font-size: 12px;
      line-height: 24px;
      width: 180px;
      text-align: left;
      padding-right: 10px;
      &.email {
        width: 250px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 60px;
        align-items: center;
      }
      &.date {
        width: 140px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 60px;
        align-items: center;
      }
      &.kyc {
        width: 250px;
        display: flex;
        flex-direction: row;
        height: 60px;
        align-items: center;
      }
      &.address {
        width: 120px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .ellipsis {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
        font-size: 22px;
        color: #c4c4c4;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: relative;
        text-align: center;
        float: right;
        &:hover {
          background-color: #eeeeee;
        }
      }
    }
  }
  .sub-row {
    background: #f4faff;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    .label {
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.44px;
      color: rgba(9, 66, 125, 0.45);
      margin-bottom: 0px;
    }
    .value {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.44px;
      color: #09427d;
    }
  }
  .ant-dispute-table {
    .ant-table-tbody {
      .ant-table-row {
        cursor: pointer !important;
      }
    }
  }

  .mt-3 {
    margin-top: 10px;
  }

  .text-cut {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.ant-dispute-table {
  .ant-table-tbody {
    .ant-table-row {
      cursor: pointer !important;
    }
  }
}

.red-bg{
  background-color: #C62300;
  width: 50%;
  align-items: center;
  padding: 5px 0px;
  color: #FFFDF0;
  padding-left: 5px;
}
.red-bg p{
  margin-bottom: 0px !important;
}

.aggregator-input {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0;
  outline: none;
  max-width: 150px;
  background: transparent;
  margin-right: 20px;
  box-shadow: none !important;

  &:active {
    border-bottom: 1px solid orange;
  }

  &:focus {
    border: none;
    border-bottom: 1px solid orange;
  }
}

.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 6em;
}

.ant-table {
  height: 100%;
}

.ant-table-wrapper {
  width: 100%;
}

.ant-table-cell {
  max-width: 18em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.display-flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-20 {
  margin-left: 20px;
}
