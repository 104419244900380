.login {
    background-color: #fafafa;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: 60px;
    }
    .box {
      background-color: #fff;
      width: 520px;
      padding: 36px;
      background: #ffffff;
      border-radius: 10px;
      text-align: left;
      .label {
        font-size: 13px;
        line-height: 16px;
        color: rgba(9, 66, 125, 0.5);
        margin-bottom: 10px;
      }
      .forgot {
        font-size: 13px;
        line-height: 16px;
        color: rgba(9, 66, 125, 0.5);
        margin-bottom: 20px;
        margin-top: -10px;
        text-align: right;
      }
      .ant-input {
        border-radius: 5px;
        height: 60px;
        border: 0.5px solid rgba(9, 66, 125, 0.3);
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.0994082px;
  
        color: #09427d;
      }
      .ant-input-password {
        height: 60px;
        border: 0.5px solid rgba(9, 66, 125, 0.3);
        border-radius: 5px;
        margin-bottom: 20px;
        input {
          border: 0 !important;
          margin-top: -6px !important;
          background-color: transparent;
        }
      }

      .request_error {
        text-align: center;
        font-weight: 600;
        color: red;
        margin-bottom: 1rem;
      }
      .main-btn {
        width: 100%;
      }
    }
  }
  