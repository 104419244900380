* {
  box-sizing: border-box;
}

@font-face {
  font-family: SF Pro Display;
  src: url("./assets/fonts/FontsFree-Net-SFProDisplay-Regular.ttf");
}

.App {
  font-family: SF Pro Display !important;
  text-align: center;
}

.main-btn {
  background: #09427d;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid #09427d;
  &:hover,
  &:focus {
    background: #09427d;
  }
  &.lg-btn {
    height: 60px;
  }
  &.md-btn {
    height: 40px;
  }
}
button {
  cursor: pointer;
}

.green--text {
  color: #47a08b !important;
}

.red--text {
  color: #d44747 !important;
}

.white-bg {
  background-color: #ffffff;
  border-radius: 5px;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.inner-options {
  width: 250px;
  border-radius: 5px;
  overflow: hidden;
  .inner-option {
    width: 100%;
    color: #ffffff;
    letter-spacing: 0.44px;
    font-weight: bold;
    font-size: 14px;
    padding: 12px 20px;
    cursor: pointer;
    background: #47a08b;
    &:hover {
      background-color: #47a08be1;
    }
  }
}

// overrides
.ant-drawer-body {
  padding: 0;
  overflow-x: hidden;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  width: 100% !important;
  white-space: break-spaces !important;
}

.ant-alert-with-description .ant-alert-message {
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.ant-pagination-item-active {
  background: #47a08b !important;
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid #47a08b;
  a {
    color: #ffffff;
  }
}
.ant-pagination-item {
  border: 0;
  letter-spacing: 0.44px;
  background-color: transparent;
  font-weight: 600;
  a {
    color: #d3d3d3;
  }
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: transparent;
  color: #47a08b;
  border: 0;
  font-weight: 600;
}

// scrollbar styling
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(226, 226, 226);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(146, 146, 146);
}

.avatar-menu {
  display: flex;
  flex-direction: row;
  .info {
    flex-direction: column;
    padding-left: 12px;
    p {
      margin-bottom: 0;
    }
    span {
      color: #47a08b;
    }
  }
}
