.home {
  .card {
    height: 170px;
    text-align: left;
    padding: 20px 25px;
    margin-bottom: 30px;
    position: relative;
    &:hover {
      box-shadow: 0px 9px 18px rgba(9, 66, 125, 0.04);
    }
    .card-title {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: rgba(9, 66, 125, 0.5);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      p {
        max-width: 50%;
      }
    }
    .rating {
      position: absolute;
      bottom: 0;
      margin-top: 30px;
      .count {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.0994082px;
        color: #09427d;
      }
      .grade {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.0994082px;
        color: #dd4545;
        padding-left: 12px;
      }
    }
  }

  .green-card {
    width: 100%;
    height: 260px;
    color: #fff;
    background: #237461;
    border-radius: 5px;
    position: relative;
    padding: 30px;
    text-align: left;
    .label {
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-bottom: 4px;
    }
    .label-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
    .amount {
      font-weight: bold;
      font-size: 23px;
      line-height: 27px;
      letter-spacing: -0.0994082px;
      color: #ffffff;
      position: absolute;
      bottom: 0;
    }
  }

  .sect-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #09427d;
    text-align: left;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
      max-width: 50%;
      line-height: 30px;
    }
  }
  .white-bg.chart {
    padding: 40px;
    height: 456px;
    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      margin-bottom: 30px;
      .indicators {
        p {
          margin-bottom: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          span {
            margin-left: 10px;
            font-size: 10px;
            line-height: 12px;
            color: rgba(9, 66, 125, 0.5);
          }
        }
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 0.5px solid rgba(9, 66, 125, 0.3);
        box-sizing: border-box;
        border-radius: 5px;
        width: 120px;
      }
    }
  }
  .items {
    padding: 0;
    height: 456px;
    overflow: auto;
    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      border-bottom: 1px solid #f7f7f7;
      padding: 19px 30px;
      .wide {
        display: flex;
        flex-direction: row;
        .info {
          padding-left: 10px;
          padding-top: 8px;
          .name {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.0747253px;
            color: #09427d;
            margin-bottom: 2px;
            font-weight: bold;
          }
          span {
            color: rgba(9, 66, 125, 0.28);
            font-size: 14px;
          }
        }
      }
      .narrow {
        padding-top: 8px;
        text-align: right;
        .amt {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.0747253px;
          color: #09427d;
          margin-bottom: 2px;
          font-weight: bold;
        }
        .percent {
          color: rgb(238, 0, 0);
          font-size: 14px;
        }
      }
    }
  }
}
